@import "colours.css";

/* Login specific styles */

.login-form {
    margin: 125px;
    padding: 18px 25px;
    box-sizing: border-box;
    border-radius: 20px;
}

.login-form.pp p {
    margin-bottom: 16px;
}

.login-form.pp ul {
    margin-left: 48px;
    margin-bottom: 16px;
}

.login-form.pp ul li {
    margin-bottom: 6px;
}

.login-form header {
    border-bottom: 1px solid var(--lightGrey);
    padding: 24px 0 36px 0;
    margin-bottom: 18px;
}
.login-form header .logo-circle {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background: #fff;
    margin: 0 auto;
    overflow: auto;
    padding-top: 8px;
    box-sizing: border-box;
    border: 4px solid var(--turquoise);
}
.login-form header img.logo-login {
    max-width: 80%;
    margin: 20px auto 8px auto;
    display: block;
}

.login-form form {
    padding: 20px 0 12px 0;
}

.login-form form a.sml {
    margin-top: 28px;
}

footer {
    position: fixed;
    bottom: 10px;

}

@media only screen and (max-width: 1080px)  {

    .login-form { width: 60%; }

}

@media only screen and (max-width: 720px)  {

    .login-form { background: none; margin-top: 25px; width: 90%; }
    .login-form header { border-bottom: none; }
    footer { display: none !important; }

}