@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,400;0,600;1,200;1,400&display=swap);
/* Large media sizes */
* {
	margin: 0;
	padding: 0;
}
header, footer { overflow: auto; margin: 0; }

.content {
	width: 1560px;
	margin: 0 auto;
	padding: 0;
	overflow: auto;
  overflow-x: hidden;
}
.width-12 {
	width: 1530px;
	margin: 0 15px;
}
.width-11 {
	width: 1400px;
	margin: 0 15px;
	display: block;
	float: left;
}
.width-10 {
	width: 1270px;
	margin: 0 15px;
	display: block;
	float: left;
}
.width-9 {
	width: 1140px;
	margin: 0 15px;
	display: block;
	float: left;
	overflow-y: hidden;
}
.width-8 {
	width: 1010px;
	margin: 0 15px;
	display: block;
	float: left;
}
.width-7 {
	width: 880px;
	margin: 0 15px;
	display: block;
	float: left;
}
.width-6 {
	width: 750px;
	margin: 0 15px;
	padding: 0;
	display: block;
	float: left;
}
.width-5 {
	width: 620px;
	margin: 0 15px;
	display: block;
	float: left;
}
.width-4 {
	width: 490px;
	margin: 0 15px;
	display: block;
	float: left;
}
.width-3 {
	width: 360px;
	margin: 0 15px;
	display: block;
	float: left;
  overflow-x: hidden;
}
.width-2 {
	width: 230px;
	margin: 0 15px;
	display: block;
	float: left;
}
.width-1 {
	width: 100px;
	margin: 0 15px;
	display: block;
	float: left;
}

.right { float: right !important; text-align: right !important; }
.left { float: left !important; text-align: left !important; }
.align-left { text-align: left; }
.align-right { text-align: right; }
.right-margin { margin-right: 50px; }
.centre { margin: 0 auto; text-align: center; }
.clear { clear: both; }
.cl-lft { clear: left; }
.cl-rght { clear: right; }
.oauto { overflow: auto; }
.small { font-size: 0.75em; }

img { border: none; }

.quarters { width: 20%; padding: 0; margin: auto 2.5%; display: block; float: left; margin-top: 60px; margin-bottom: 60px; }
.thirds { width: 30%; float: left; margin: 0 1.5%; }

.two-thirds { width: 65%; float: left; margin-right: 2%; box-sizing: border-box; }
.one-third { width: 33%; float: left; margin: 0; box-sizing: border-box; }

/* Specific Wireframes Styles */
.centred { float: none !important; margin-left: auto !important; margin-right: auto !important; float: none !important; overflow: auto; }
.empty-textarea { min-height: 50px; background: red; }
a.block-btn { display: block; text-align: center; background: #444; color: #fff; padding: 8px 15px; margin: 0 0 5px 0; }

.drop-highlight { height: 100px; background: #dfdfdf; }

/* CMS styles */

/* Less than 960 breakpoint */
/* Below 960 we start by scaling */

@media only screen and (max-width: 1560px)  {

	.content {
		width: 100%;
		margin: 0 auto;
		padding: 0;
	}
	.width-12 {
		width: 97.5%;
		margin: 0 1.25%;
	}
	.width-11 {
		width: 89.2%;
		margin: 0 1.25%;
	}
	.width-10 {
		width: 80.8%;
		margin: 0 1.25%;
	}
	.width-9 {
		width: 72.5%;
		margin: 0 1.25%;
	}
	.width-8 {
		width: 64.2%;
		margin: 0 1.25%;
	}
	.width-7 {
		width: 55.8%;
		margin: 0 1.25%;
	}
	.width-6 {
		width: 47.5%;
		margin: 0 1.25%;
	}
	.width-5 {
		width: 39.2%;
		margin: 0 1.25%;
	}
	.width-4 {
		width: 30.8%;
		margin: 0 1.25%;
	}
	.width-3 {
		width: 22.5%;
		margin: 0 1.25%;
	}
	.width-2 {
		width: 14.1%;
		margin: 0 1.25%;
	}
	.width-1 {
		width: 5.8%;
		margin: 0 1.25%;
	}

	.width-8 .width-8, .width-9 .width-9 { width: 100%; }


}
@media only screen and (max-width: 1220px)  {
	.content {
		width: 95%;
		margin: 0 auto;
		padding: 0;
	}
}
@media only screen and (max-width: 800px)  {
	.width-6 {
		width: 75%;
		margin: 0 1.25%;
	}

	.two-thirds { width: 100%; float: none; margin-right: 0; }
	.one-third { width: 100%; float: none; margin-top: 12px; }
}
@media only screen and (max-width: 680px)  {
	.content.admin { width: 95%; }
}
/* Less than 720 breakpoint */
/* Switch to single column for mobile mobile */

@media only screen and (max-width: 1400px)  {
	.rx-1400-hidden{
		display:none!important
	}



	
}

@media only screen and (max-width: 1100px)  {
	.rx-1100-hidden{
		display:none!important
	}

	
}

@media only screen and (max-width: 1000px)  {
	.width-10 {
		width: 75.8%;
		margin: 0 1.25%;
	}
	.width-2 {
		width: 19.1%;
		margin: 0 1.25%;
	}
}

@media only screen and (max-width: 800px)  {

	.content {
		width: 95%;
		margin: 0 auto;
		padding: 0;
	}
	.width-12, .width-11, .width-10, .width-9, .width-8, .width-7, .width-6, .width-5, .width-4, .width-3, .width-2, .width-1, .block, .form-block {
		width: 95%;
		margin: 0 2.5% 0 1.5%;
	}

	.rx-800-hidden{
		display:none!important
	}

	.mobile-navigation{
		display:block!important;
	}

	nav.desktop-navigation{
		display:none!important;
	}

}

:root {
  --bgGrey: #1f1a1e;
  --bgGreyRGB: rgba(239,239,239,1);
  --bgGreyRGB0: rgba(239,239,239,0);
  --bgWhite: #302b31;
  --bgWhiteTrans: #302b3188;
  --bgWhite0: rgba(255,255,255,0);
  --shadow: rgba(255,255,255,0.05);
  --hardShadow: rgba(255,255,255,0.25);
  --txt: #FEFEFF;
  --txtWhite: #fff;
  --txtDarkGrey: #999;
  --txtGrey: #bbb;
  --lightGrey: #ededed;
  --lightGreyOnGrey: #979696;
  --lightbox-bg: rgba(100, 100, 100, 0.58);

  --turquoise: #1A9F95;
  --lghtTurquoise: #1A9F9544;
  --lghtRed: #fd94a7;
  --red: #ff1e48;
  --green: #79bc66;
  --darkGreen: #57a042;
  --yellow: #faa83b;
  --purple: #a667ef;
  --blue: #45b9e7;
}

/* Body styles, heading + generic classes */
body {
    background: var(--bgGrey);
    font-family: 'Source Sans Pro', sans-serif;
    color: var(--txt);
    font-size: 16px;
}
.bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: url('/core/circles.png') right bottom no-repeat;
    z-index: -1;
}

.spaced-half {
    width: 45%;
    float: left;
    margin-right: 5%;
}
.spaced-half+.spaced-half {
    margin-right: 0;
    margin-left: 5%;
}

.white-bg {
    box-shadow: 1px 1px 5px var(--shadow);
    background: var(--bgWhite);
    border-radius: 4px;
    overflow: auto;
    padding: 8px 12px;
    box-sizing: border-box;
}

.dashboard-panel{
    box-shadow: 1px 1px 5px var(--shadow);
    background: var(--bgWhite);
    overflow: auto;
    padding: 18px 25px;
    box-sizing: border-box;
    min-height:500px;
    border-radius: 10px;
    margin-top: 22px;
}

.dashboard-panel-title{
    margin-bottom:16px;
    padding-bottom:12px;
    border-bottom:1px solid var(--turquoise);
    font-size:24px;
}

.panel-list-item{
    margin-bottom:12px;
    padding-bottom:12px;
    border-bottom:1px solid var(--lightGrey)
}

p.empty-p {
    font-style: italic;
    color: var(--txtGrey);
}

.panel-list-item .status{
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background: var(--blue);
    text-align: center;
    float:right;
    cursor:pointer;
    font-size: 14px;
    padding: 3px 0 0 0;
    box-sizing: border-box;
}
.panel-list-item button.sml.clr {
    margin-right: 8px;
    padding: 4px 8px;
    border-radius: 8px;
    background: var(--bgGrey);
    border-color: var(--bgGrey);
}

.panel-list-item .status.complete{
    
    background: var(--green);
}
.panel-list-item .detail {
    color: var(--turquoise);
}

.panel-list-item h3{
    font-weight:normal;
    font-size:16px;
}

.panel-list-item p{
    font-size:13px
}

.clearer{
    clear:both;
}

ul.proper { list-style: disc; margin-left: 20px; }
.text-left{
    text-align:left;
}

.text-center{
    text-align:center;
}
.dashboard-panel .text-center {
    margin-top: 28px;
    margin-bottom: 12px;
}

.text-right{
    text-align:right;
}

h1 {
    font-size: 2.5em;
    font-weight: 600;
    padding: 0 0 5px 0;
    margin-bottom: 22px;
    border-bottom: 1px solid var(--lightGreyOnGrey);
}
h1.report-title {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0;
}
h2.report-title {
    padding: 0 0 5px 0;
    margin-bottom: 32px;
    font-weight: normal;
    border-bottom: 1px solid var(--lightGreyOnGrey);
}
h2.report-title.half {
    width: 50%;
    float: left;
    font-size: 20px;
    opacity: 0.6;
}

a { color: var(--turquoise); text-decoration: underline;}
a:hover { text-decoration: none;}

a.btn { 
    background: var(--lightGreyOnGrey);
    border-radius: 4px;
    padding: 5px 18px;
    font-size: 0.9em;
    text-decoration: none;
    color: var(--txt);
    cursor: pointer;
    transition: 0.25s;
}
a.btn:hover {
    background: var(--lightbox-bg);
}

a.btn.report-btn { 
    margin-right:5px;
}
a.btn.right { 
    margin-left: 8px;
}

button.approve-btn {
    font-size: 0.9em;
    padding: 4px 18px;
    margin-left: 8px; 
}

.sml { font-size: 0.8em; }
.mrgn-lrg { margin-top: 42px; }
table td.empty-row, span.empty-span { text-align: center; font-style: italic; color: var(--txtGrey); }

a.selected-lnk { color: var(--turquoise) !important; }

.flash {
    width: 100%;
    background: var(--red);
    padding: 5px 12px;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 22px;
}
.flash.green {
    background: var(--turquoise);
}

/* Header and footer styles */
header.main {
    background: var(--bgWhite);
    padding: 18px 0;
    box-shadow: 1px 1px 8px var(--shadow);
    margin-bottom: 72px;
    font-size: 0.9em;
    overflow: visible;
    height: 48px;
}
header .content { overflow: visible; }
header.main.mobile-open  {
    margin-bottom: 0;
    z-index: 2;
    border-bottom: 1px solid var(--bgGrey);
}

header .logo {
    width: 125px;
    height: 125px;
    background: var(--txt);
    border-radius: 50%;
    box-sizing: border-box;
    border: 3px solid var(--turquoise);
    box-shadow: 1px 1px 5px var(--hardShadow);
    float: left;
    margin-bottom: -50px;
}
header .logo img {
    width: 80%;
    display: block;
    margin: 0 auto;
    padding-top: 28px;
}

header nav ul {
    list-style: none;
    text-align: right;
    margin-top: 14px;
}
header nav ul li {
    display: inline-block;
    margin-left: 20px;
}
header nav ul li.logout {
    border-left: 1px solid var(--txtGrey);
    margin-left: 28px;
}
header nav ul li a {
    color: var(--txtDarkGrey);
    text-decoration: none;
    padding-left: 38px;
}
header nav ul li.logout a { padding-left: 24px; }

header nav ul li a.dashboard, nav.hamburger-content-nav ul li a.dashboard { background: url('/core/dashboard.svg') left 12px center / 19px auto no-repeat; }
header nav ul li a.projects, nav.hamburger-content-nav ul li a.projects { background: url('/core/paint.svg') left 12px center / 19px auto no-repeat; }
header nav ul li a.toolbox, nav.hamburger-content-nav ul li a.toolbox { background: url('/core/toolbox.svg') left 14px center / 17px auto no-repeat; }
header nav ul li a.datasheet, nav.hamburger-content-nav ul li a.datasheet { background: url('/core/datasheet.svg') left 14px center / 19px auto no-repeat; }
header nav ul li a.users, nav.hamburger-content-nav ul li a.users { background: url('/core/account.svg') left 14px center / 17px auto no-repeat; }
header nav ul li a.settings, nav.hamburger-content-nav ul li a.settings { background: url('/core/settings.svg') left 14px center / 17px auto no-repeat; }

div.hamburger-content {
    display:none
}

div.hamburger-content.hamburger-dropdown-visible {
    display:block;
    background: var(--bgWhite);
    margin: 0 0 24px 0;
}
nav.hamburger-content-nav ul {
    text-align: center;
}
nav.hamburger-content-nav ul li {
    display: block;
    padding:0;
    margin: 0 20px;
}
nav.hamburger-content-nav ul li.logout {
    border-top: 1px solid var(--bgGrey);
    border-left:none;
    padding-left: 0;
}
nav.hamburger-content-nav ul li a {
    padding: 14px 0 14px 42px;
    display: inline-block;
    color: var(--txtDarkGrey);
    text-decoration: none;
}

nav.hamburger-content-nav ul li.logout a { padding-left: 30px; }

div.mobile-navigation{
    width:32px;
    height:32px;
    float:right;
    cursor:pointer;
    display:none
}

div.mobile-navigation .line{
    background-color: var(--txtGrey);
    height:5px;
    width:32px;
    margin:5px 2px;
    border-radius:2px

}


footer {
    text-align: center;
    width: 100%;
    font-size: 0.8em;
    color: var(--txtGrey);
}

/* Generic form styles */
.input {
    margin-bottom: 12px
}
.input.checkbox {
    margin-bottom: 8px;
}

.input label {
    display: block;
    font-size: 0.8em;
    margin-bottom: 5px;
}
.input label.highlight:after {
    content: "!";
    display: inline-block;
    margin-left: 12px;
    text-align: center;
    font-weight: bold;
    font-size: 10px;
    width: 14px;
    height: 14px;
    border-radius: 8px;
    background: var(--red);
    color: var(--txtWhite);
}
.input.checkbox label {
    display: inline;
    margin-left: 8px;
}

.form-section.confirm {
    background: var(--lghtTurquoise);
    padding: 8px 15px 4px 15px;
    border-radius: 4px;
    margin-top: 18px !important;
    margin-bottom: 18px !important;
}

.input input[type=text], .input input[type=email], .input input[type=password],.input input[type=tel], .input select,.input textarea{
    background: var(--bgGrey);
    border: 1px solid var(--lightGrey);
    padding: 8px 12px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    font-size: 1em;
    font-family: 'Source Sans Pro', sans-serif;
    color: var(--txt);
    outline: none;
}

.input textarea {
    height:120px;
    resize: none;
}
.input input[type=text]:focus, .input input[type=email]:focus, .input input[type=password]:focus,.input input[type=tel]:focus, .input select:focus {
    border: 1px solid var(--turquoise);
}

.email-search .input select {
    width: unset;
    float: right;
    margin-top: 8px;
}

.form-page .input input[type=text],.form-page .input input[type=email],.form-page .input input[type=password],.form-page .input input[type=tel],.form-page .input select
{
    background: var(--bgWhite);
}

.react-datepicker-wrapper {
    width: 100%;
}

button, a.btn-dark {
    border: 1px solid var(--turquoise);
    background: var(--turquoise);
    color: var(--txtWhite);
    text-decoration: none;
    border-radius: 4px;
    font-size: 1em;
    padding: 5px 12px;
    font-family: 'Source Sans Pro', sans-serif;
    transition: 0.5s;
    cursor: pointer;
    outline: none;
}

a.btn.email-btn {
    font-size: 1em;
    padding: 5px 12px;
    border: 1px solid var(--lightGreyOnGrey);
    margin-right: 8px;
}

button.submit.submit-disabled{
    color:var(--txtGrey)
} 

.google-places-autocomplete > div {
    padding: 0;
    margin: 0 1px;
    border: 1px solid var(--lightGreyOnGrey);
    border-top: none;
}
.google-places-autocomplete > div > div {
    padding: 5px 8px;
    cursor: pointer;
    transition: 0.25s;
}
.google-places-autocomplete > div > div+div {
    border-top: 1px solid var(--lightGreyOnGrey);
}
.google-places-autocomplete > div > div:hover {
    background: var(--lghtTurquoise);
}

a.btn-dark {
    padding: 4px 15px;
    font-size: 0.9em;
    text-decoration: none;
}
button:hover, a.btn-dark:hover {
    background: var(--bgWhite);
    color: var(--turquoise);
}
button.submit {
    padding: 8px 25px;
    margin: 18px 0 12px 0;
}

button.submit.submit-disabled:hover{
    border: 1px solid var(--turquoise);
    background: var(--turquoise);
    transition: none !important;
} 


.input label img.flag {
    height: 10px;
    width: auto;
    display: inline-block;
    margin: 0 5px 0 0;
    box-shadow: 1px 1px 3px var(--hardShadow);
}

button.revert-btn {
    background-color: none;
    background: url('/undo.svg') center center / 16px auto no-repeat;
    font-size: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    border: none;
    float: right;
}

/* Flash messages */
.message {
    border-radius: 4px;
    padding: 8px 15px;
    border: 1px solid var(--red);
    background: var(--red);
    color: var(--txtWhite);
    box-shadow: 1px 1px 5px var(--shadow);
}
.message.success {
    background: var(--green);
    border-color: var(--darkGreen);
}
.message.mbtm { margin-bottom: 12px; }
.val-error { color: var(--red); }

/* Tables + pagination */
table { 
    border-collapse: collapse;
    margin-top: 32px;
    text-align: left;
    width: 100%;
}
table thead th {
    padding: 12px 8px;
    font-weight: 600;
    font-size: 1.1em;
    border-bottom: 2px solid var(--turquoise);
    color: var(--turquoise);
}
table th a.orderer {
    text-decoration: none;
}
table th a.orderer.asc:before {
    content: "⇡";
    font-size: 0.7em;
}
table th a.orderer.desc:before {
    content: "⇣";
    font-size: 0.7em;
}

table tbody td {
    padding: 10px 8px;
    border-bottom: 1px solid var(--lightGreyOnGrey);
}
table tbody td img.flag {
    height: 22px;
    width: auto;
    margin: 0 auto;
    display: block;
    box-shadow: 1px 1px 5px var(--shadow);
}
table tr.cancelled td {
    text-decoration: line-through;
    color: var(--red);
}


table.reports-table{
    margin-top:0
}

.paging {
    font-size: 0.8em;
    color: var(--txtGrey);
    margin-top: 16px;
}
ul.paging {
    list-style: none;
    float: right;
}
ul.paging li {
    display: inline-block;
    padding: 2px;
}

/* Status icons */
.status-icon {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 8px;
}
.status-icon.green { background: var(--green); }
.status-icon.red { background: var(--red); }
.status-icon.yellow { background: var(--yellow); }
.status-icon.blue { background: var(--turquoise); }


/* Other misc */
.stats {
    border: 1px solid var(--turquoise);
    padding: 22px;
}
.stats .thirds { text-align: center; }
.stats .thirds span {
    font-weight: 600;
    font-size: 2.2em;
    display: block;
}
.filter {
    padding: 12px 25px;
    background: var(--lghtTurquoise);
    border-radius: 12px;
}
.filter .input {
    width: 47.5%;
    margin-right: 5%;
    float: left;
}
.filter .input:nth-child(even) {
    margin-right: 0;
}
.filters h3 {
    font-size: 1.3em;
    padding: 8px 12px;
    background: var(--lghtTurquoise);
    border-radius: 4px;
    margin: 6px 0; 
    cursor:pointer;
}
.filters > div {
    margin-bottom: 22px;
}

.lightbox {
    position:fixed;
    width:100%;
    height:100%;
    background-color:var(--lightbox-bg);
    z-index:1;
}

.lightbox .lightbox-content{
    width:600px;
    margin:0 auto;
    margin-top:10vh;
    background-color:var(--bgWhite);
    padding:25px;
    border-radius:10px;
    position:relative;
    overflow:auto;
    max-height: 80vh;
    box-sizing: border-box;
}

.lightbox .lightbox-content.external-report-content{
    width:800px;
}

.lightbox .lightbox-header h4 {
    font-size: 24px;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--lightGrey);
    margin: 0 0 12px 0;
}

p.lightbox-header { color: var(--turquoise); }

.lightbox .close {
    font-size:40px;
    position:absolute;
    right: 22px;
    top: 5px;
    color:var(--txtDarkGrey);
    cursor:pointer;
}

.clear-btn{
    float:right;
    margin-right: 25px;
    margin-top: -7px;
}

.lightbox .lightbox-section {
    float:left;
    width:100%;
    margin-bottom:10px;
    padding-bottom:10px;
    margin-top:10px;

}

.lightbox .lightbox-section.lightbox-border-bottom {
    border-bottom:1px solid var(--lightGrey)
}

.lightbox .lightbox-content .lightbox-section-third{
    width:31%;
    margin-right:2%;
}

.lightbox .lightbox-content .lightbox-section-half{
    width:49%;
    margin-right:1%;
}

.lightbox .lightbox-section.clear {
    clear: left;
}

.lightbox .lightbox-section .lightbox-info {
    font-size:16px
}

.lightbox .lightbox-section .lightbox-date {
    font-size:13px
}

.lightbox .lightbox-section p { font-size: 0.8em; word-wrap: break-word; }


.lightbox-content > h4.subtitle {
    font-weight:normal;
    font-size:18px;
    clear: both;
    padding-top: 24px;
}

.lightbox .lightbox-submit {
    clear:both;
    text-align: center;
}

.form-page .edit-form {
    overflow: auto;
}

.form-page .group { margin-bottom: 32px; }
.form-page .group:nth-child(3n+1) { clear: left; }
.form-page .group h4 {
    font-size:20px;
    padding-bottom:10px;
    margin:20px 0 20px 0;
    border-bottom:1px solid var(--lightGreyOnGrey);
}

.lightbox.lightbox-create-booking .lightbox-section,.lightbox.lightbox-create-user .lightbox-section,.form-page .form-section{
    margin-bottom:0px;
    margin-top:10px;
}

table.manage-users-table .btn{
    margin-right:5px;
}

.project-edit-td a{
    margin-right:3px;
}

.project-edit-td a.project-edit-publish-toggle,.manage-users-table a.user-lock-toggle{
    width:60px;
    display:inline-block;
    text-align:center
}

.id-box{
    height:50px;
    line-height:50px;
    font-size:30px;
    background-color: var(--bgWhite);
    border:1px solid var(--txtDarkGrey);
    padding:0 5px;
    margin-right:10px;
}

div.change-log {
    padding: 12px;
    background: var(--bgWhite);
    margin-bottom: 48px;
    border-radius: 0 0 4px 4px;
    box-shadow: 1px 1px 5px var(--shadow);
}
div.change-log table{
    margin-top: 0;
}
div.change-log table tr td {
    border: none;
}
div.change-log table tr+tr td {
    border-top: 1px solid var(--lghtTurquoise);
}

div.change-log table span.reverted {
    color: var(--red);
}
div.change-log table span.reverted span {
    text-decoration: line-through;
}

div.change-log-title{
    padding:10px 0 10px 56px;
    cursor:pointer;
    color: var(--txtWhite);
    background: var(--turquoise) url('/opener.svg') left 14px top 13px / 28px auto no-repeat; 
    border-radius: 4px;
    box-shadow: 1px 1px 5px var(--shadow);
    margin-bottom: 42px;
}
div.change-log-title.open {
    background-position: left 14px top -28px; 
    border-radius: 4px 4px 0 0;
    margin-bottom: 0;
}

.setting-container{
    display:inline-block
}

.dashed-divider{
    margin:20px 0;
    border-bottom:1px dashed var(--lightGreyOnGrey);
}

.error-box{
    margin-top:72px;
    padding: 200px 20px 0 20px;
    background: url('/compass.svg') center top / 175px auto no-repeat;
}

.error-box h1{
    font-size: 32px;
    color: var(--turquoise);
    border: none;
}
.error-box p{
    margin-bottom: 12px;
}

.toggle-btn{
    margin-right:5px;
    margin-bottom: -2px;
}

.report-type-panel li{
    list-style-type: none;
    margin:20px 0;
}

.report-type-panel.report-type-selector-panel h4 {
    margin-bottom: 14px;
    font-size: 1.2em;
    border-bottom: 1px solid var(--hardShadow);
    padding-bottom: 8px;
}
.report-type-panel.report-type-selector-panel h4.mtop {
    margin-top: 28px;
}

.report-type-panel.report-type-selector-panel li{
    list-style-type: none;
    margin:12px 0;
}

.report-type-panel li a{
    color:var(--txt);
    text-decoration:none;
    
}

/* Preloader */
.loader {
    width: 100px;
    display: block;
    margin: 10px auto;
    position: relative;
}
.loader.mrgn-lrg { margin: 28px auto 14px auto; }
.loader.center-page {margin-top:40vh}
.loader.table-loader {margin-top:50px;}
.loader.loader-icon-small{width:60px;}

.folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotateZ(45deg);
}

.folding-cube .cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1); 
}
.folding-cube .cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  animation: foldCubeAngle 2.4s infinite linear both;
  transform-origin: 100% 100%;
}
.folding-cube .cube2 {
  transform: scale(1.1) rotateZ(90deg);
}
.folding-cube .cube3 {
  transform: scale(1.1) rotateZ(180deg);
}
.folding-cube .cube4 {
  transform: scale(1.1) rotateZ(270deg);
}
.folding-cube .cube2:before {
  animation-delay: 0.3s;
}
.folding-cube .cube3:before {
  animation-delay: 0.6s; 
}
.folding-cube .cube4:before {
  animation-delay: 0.9s;
}

@keyframes foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  }
}


.item-of-concern-btn-container{
    float:right;
    min-width: 70px;
    margin-left: 12px;
}

.item-of-concern-btn-container .btn{
    display:block;
    text-align:center;
    padding:4px 8px;
    font-size:13px;
    margin-bottom:3px
}

.form-upload-label{
    display:block;
    width:100%;
    padding: 24px 12px;
    border:1px var(--txtWhite) dashed;
    box-sizing: border-box;
    
}

.form-upload-label:hover{
    border:1px var(--turquoise) dashed;
    cursor: pointer;
}

.file-uploader{
    opacity:0;
    position:absolute
}

@keyframes heartRateIn {
  0% {
    width: 100%;
  }
  50% {
    width: 0;
  }
  100% {
    width: 0;
  }
}

@keyframes heartRateOut {
  0% {
    left: -120%;
  }
  30% {
    left: -120%;
  }
  100% {
    left: 0;
  }
}

div.report-content {
    /* width:100%; */
    /* background-color:white; */
	/* color:black; */
	overflow: hidden;
}

.sxn-divider {
    border-color: #ffffff17;
    margin: 10px 0px 10px 0px;
}

.report-question-group {
	/* background: #302b31; */
	border-radius: 4px;
	padding: 10px 10px;
	margin-bottom: 10px;
}

.slick-slider {
	min-height: 150px;
    padding-bottom: 40px;
}
.slick-next {
	left: 52% !important;
	/* bottom: 0 !important; */
	top: auto !important;
    bottom: -7px !important;
}
.slick-prev {
	left: 48% !important;
	top: auto !important;
    /* bottom: 0 !important; */
    bottom: -7px !important;
}

.report-question-group.tabbed {
    padding-top: 32px;
    padding-bottom: 0;
    margin-bottom: 0;
}
.report-question-group.tabbed + .report-question-group {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 32px;
}

table.temp {
    border-collapse: separate;
    margin-top: 0;
    margin-bottom: 0;
}
table.temp tr td {
    border-bottom: none;
}
table.temp tr td:first-child { width: 40% !important; text-align: left; }
.report-table-grey-cell {
	background: #302b31;
    text-align: center;
	padding: 20px 30px;
	width: 30% !important;
	font-size: 3vw;
}
.report-table-label {
	width: 40%;
}
.report-table-row {
	/* border-top: solid 2px black; */
}
.report-table-header-labels .report-table-grey-cell {
    margin-right: 2px;
    border-radius: 8px 8px 0 0;
}
.basic-field-entry-container {
    margin: 7px;
	clear: both;
}
.basic-field-entry {
	background: var(--bgGrey);
	border: solid thin rgba(255,255,255,0.5);
	color: var(--txtWhite);
	border-radius: 4px;
	padding: 10px 14px;
    width: 100%;
    box-sizing: border-box;
    display: inline-block;
    margin: 5px auto;
}
.basic-field-entry.comment {
    min-height: 75px;
    color: var(--txtGrey);
    margin-bottom: 18px;
}

.cb-field-entry-container {
    margin-bottom: 16px;
}
.cb-field-entry-container .cb-field-entry-label {
    padding-bottom: 5px;
    border-bottom: 1px dotted var(--lightbox-bg);
    margin-bottom: 12px;
    margin-top: 12px;
}
.cb-field-entry-container .cb-entries {
    overflow: auto;
}
.cb-field-entry-container .cb-entries .option {
    width: 31%;
    margin: 0 1% 12px 1%;
    float: left;
}
.cb-field-entry-container .cb-entries .option img {
    width: 24px;
    height: auto;
    float: left;
    margin-right: 12px;
}

.report-slider-buttons {
	width: 100%;
    margin: auto;
}
.report-slider-button {
	background: #79bc66;
	padding: 15px 0px;
	width: 155px;
	text-align: center;
    border-radius: 25px;
	display: inline-block;
    cursor: pointer;
    float: right;
	background: #1a9f95;
}
.report-slider-button.prev {
	float: left;
	background: rgba(21,159,149,0.3);
}

.report-slider-button:hover {
	background: #1a9f9521;
	transition: .2s all;
}
.halfOdd, .halfEven {
	width: 50%;
	display: inline-block;
}
.repeater-wrap {
    overflow: auto;
}
.repeater-section {
	padding: 15px;
	margin: 0 1% 15px 1%;
    border-radius: 4px;
    width: 48%;
    float: left;
    box-sizing: border-box;
}
.repeater-section .basic-field-entry-container {
	margin: 0 0 7px 0;
}
.repeater-section .basic-field-entry-container.halfOdd {
    width: 49%;
    margin-right: 1%;
}
.repeater-section .basic-field-entry-container.halfEven {
    width: 49%;
    margin-left: 1%;
}
.basic-field-entry-label {
    padding: 0 7px;
    color: var(--txtGrey);
}
.date-issue {
    color: var(--red);
    font-size: 0.85em;
    float: left;
}
.yes-no-entry-container {
	display: block;
	margin-bottom: 15px;
}
.yes-no-entry-label {
	float: left;
}
.yes-no-entry {
	float: right;
	color: black;
    background: #ffffff45;
	border-radius: 4px;
}
.yes-no-entry > span {
    padding: 2px 9px;
	font-weight: bold;
}
.yes-no-entry > span.yes {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	float: left;
}
.yes-no-entry > span.no {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	float: right;
}
.yes-no-entry.yes > span.yes {
    box-shadow: inset 0 0 0 1000px #1a9f95;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.yes-no-entry.no > span.no {
    box-shadow: inset 0 0 0 1000px #ff1e48;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.report-question-group.boxed-img, .report-question-group.boxed, .report-question-group.plain .repeater-section, .report-question-group.grey .repeater-section, .report-question-group.half .repeater-section {
	background: #f3f3f314;
}
.yes-no-img-entry-container {
    width: 50%;
    text-align: center;
    padding: 20px;
	box-sizing: border-box;
	display: inline-block;
    float: left;
}
.yes-no-img-entry-container .yes-no-entry-label {
	float: none;
    margin: 10px auto 7px auto;
	width: 140px;
	max-width: 100%;
}
.yes-no-img-entry-container .yes-no-entry, .yes-no-img-entry-container .yes-no-entry span.yes, .yes-no-img-entry-container .yes-no-entry span.no {
	float: none;
	display: inline-block;
}
.yes-no-img-entry-container .yes-no-entry {
	margin: 5px auto;
}
.yes-no-img-image {
	height: 60px;
	width: 60px;
	background-position: center;
	background-size: cover;
	border-radius: 50%;
	margin: 0px auto;
}
.section-group-title {
	border-bottom: solid thin #8080804f;
	margin-bottom: 3px;
    padding-bottom: 3px;
}
.basic-field-entry-container.TextArea .basic-field-entry {
	min-height: 100px;
}

.inc-lnk { position: relative; }
.full-lnk, .full-lnk:hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: none;
    border: none;
}

@media only screen and (max-width: 1080px)  {
	header nav ul li {
        margin-left: 0px;
    }
    header nav ul li.logout {
        border-left: 1px solid var(--txtGrey);
        margin-left:12px;
    }

    header nav ul li.logout a{
        padding-left: 12px
        
    }
    header nav ul li a {
        color: var(--txtDarkGrey);
        text-decoration: none;
        padding-left: 38px;
    }
	
}

@media only screen and (max-width: 980px)  {

    .repeater-section {
        width: 98%;
        float: none;
    }

    .cb-field-entry-container .cb-entries .option { width: 98%; }


}

@media only screen and (min-width: 800px)  {
	div.hamburger-content.hamburger-dropdown-visible{
		display:none!important
    }
    

}

@media only screen and (max-height:900px)  {
    .lightbox .lightbox-content{
        margin-top:5vh;
        /* overflow-y:scroll; */
        max-height: 90vh;
        box-sizing: border-box;
    }
}


@media only screen and (max-width: 800px)  {
	h1{
        font-size:1.775em
    }

    .stats .thirds span {
        font-size:1.5em
    }

    .filters{
        margin:20px 2.5%;
    }


    .lightbox .lightbox-content,.lightbox .lightbox-content.external-report-content{
        width:80%;
        margin-top:5vh;
        /* overflow-y:scroll; */
        max-height: 90vh;
        box-sizing: border-box;
    }

    .lightbox .lightbox-content .lightbox-section,.lightbox .lightbox-content.external-report-content .lightbox-section{
        width:100%
    }

  

    .spaced-half{
        width:90%;
        margin-bottom:30px;
        margin-left:0!important;
    }

    .stats{
        padding:10px
    }

    div.hamburger-content.hamburger-dropdown-visible{
		display:block!important
    }

    header img.logo {
        max-width:200px
    }

    .edit-form .group{
        margin-bottom:10px;
    }

    .id-box{
        height:40px;
        line-height: 35px;
        margin-right:20px
    }

    .manage-users-table .btn{
        padding:5px 12px;
    }
}

@media only screen and (max-width: 500px)  {    
    .report-table-grey-cell {
        padding-left: 5px;
        padding-right:5px;
    }
}

/* Print friendly styling */
header.print.main, .print {
    background: var(--txtWhite);
    color: var(--bgGrey);
    height: auto;
}
header.print.main, header.print.main .content {
    margin-bottom: 0;
    overflow: auto;
    height: auto;
}
header.print.main .width-2.logo-container {
    margin: 18px auto 32px auto;
    float: none;
}
header.print.main .width-2.logo-container .logo {
    margin: 0 auto 22px auto;
}
.print .basic-field-entry-label {
    color: var(--txtDarkGrey);
}
.print .basic-field-entry {
    background: none;
    color: var(--bgGrey);
    border-color: var(--txtGrey);
}
.print .yes-no-img-entry-container {
    width: 25%;
}
.print .yes-no-entry {
    border: 1px solid var(--txtGrey);
    padding: 1px;
}
.print h4 {
    margin-top: 38px;
}
.print hr.sxn-divider {
    border-bottom-color: var(--txtGrey);
}
.print .boxed, 
.print .boxed-img, 
.print .report-question-group.grey .repeater-section, 
.print .report-question-group.half .repeater-section, 
.print .report-question-group.plain .repeater-section {
    background: var(--lightGrey);
}
.print .report-table-grey-cell {
    background: var(--lightGrey);
}
.print footer {
    display: none !important;
}

.dashbaord-panel-deleted-project > a {
    opacity: 0.5;
}

.yn-img { display: none; }
@media print {
    header.print.main .width-2.logo-container .logo { width: 120px; height: 120px; margin: 22px 30%; }
    .print > div.report-content {
        width: 90%;
        height: 90%;
        margin: 32px 5%;
    }
    .yes-no-img-image { display: none !important;}
    .yn-img { display: block; max-height: 60px; max-width: 60px; margin: 0 auto; }
    .yes-no-img-entry-container { height: 200px; }

}
/* Login specific styles */

.login-form {
    margin: 125px;
    padding: 18px 25px;
    box-sizing: border-box;
    border-radius: 20px;
}

.login-form.pp p {
    margin-bottom: 16px;
}

.login-form.pp ul {
    margin-left: 48px;
    margin-bottom: 16px;
}

.login-form.pp ul li {
    margin-bottom: 6px;
}

.login-form header {
    border-bottom: 1px solid var(--lightGrey);
    padding: 24px 0 36px 0;
    margin-bottom: 18px;
}
.login-form header .logo-circle {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background: #fff;
    margin: 0 auto;
    overflow: auto;
    padding-top: 8px;
    box-sizing: border-box;
    border: 4px solid var(--turquoise);
}
.login-form header img.logo-login {
    max-width: 80%;
    margin: 20px auto 8px auto;
    display: block;
}

.login-form form {
    padding: 20px 0 12px 0;
}

.login-form form a.sml {
    margin-top: 28px;
}

footer {
    position: fixed;
    bottom: 10px;

}

@media only screen and (max-width: 1080px)  {

    .login-form { width: 60%; }

}

@media only screen and (max-width: 720px)  {

    .login-form { background: none; margin-top: 25px; width: 90%; }
    .login-form header { border-bottom: none; }
    footer { display: none !important; }

}
