:root {
  --bgGrey: #1f1a1e;
  --bgGreyRGB: rgba(239,239,239,1);
  --bgGreyRGB0: rgba(239,239,239,0);
  --bgWhite: #302b31;
  --bgWhiteTrans: #302b3188;
  --bgWhite0: rgba(255,255,255,0);
  --shadow: rgba(255,255,255,0.05);
  --hardShadow: rgba(255,255,255,0.25);
  --txt: #FEFEFF;
  --txtWhite: #fff;
  --txtDarkGrey: #999;
  --txtGrey: #bbb;
  --lightGrey: #ededed;
  --lightGreyOnGrey: #979696;
  --lightbox-bg: rgba(100, 100, 100, 0.58);

  --turquoise: #1A9F95;
  --lghtTurquoise: #1A9F9544;
  --lghtRed: #fd94a7;
  --red: #ff1e48;
  --green: #79bc66;
  --darkGreen: #57a042;
  --yellow: #faa83b;
  --purple: #a667ef;
  --blue: #45b9e7;
}
