/* Large media sizes */
* {
	margin: 0;
	padding: 0;
}
header, footer { overflow: auto; margin: 0; }

.content {
	width: 1560px;
	margin: 0 auto;
	padding: 0;
	overflow: auto;
  overflow-x: hidden;
}
.width-12 {
	width: 1530px;
	margin: 0 15px;
}
.width-11 {
	width: 1400px;
	margin: 0 15px;
	display: block;
	float: left;
}
.width-10 {
	width: 1270px;
	margin: 0 15px;
	display: block;
	float: left;
}
.width-9 {
	width: 1140px;
	margin: 0 15px;
	display: block;
	float: left;
	overflow-y: hidden;
}
.width-8 {
	width: 1010px;
	margin: 0 15px;
	display: block;
	float: left;
}
.width-7 {
	width: 880px;
	margin: 0 15px;
	display: block;
	float: left;
}
.width-6 {
	width: 750px;
	margin: 0 15px;
	padding: 0;
	display: block;
	float: left;
}
.width-5 {
	width: 620px;
	margin: 0 15px;
	display: block;
	float: left;
}
.width-4 {
	width: 490px;
	margin: 0 15px;
	display: block;
	float: left;
}
.width-3 {
	width: 360px;
	margin: 0 15px;
	display: block;
	float: left;
  overflow-x: hidden;
}
.width-2 {
	width: 230px;
	margin: 0 15px;
	display: block;
	float: left;
}
.width-1 {
	width: 100px;
	margin: 0 15px;
	display: block;
	float: left;
}

.right { float: right !important; text-align: right !important; }
.left { float: left !important; text-align: left !important; }
.align-left { text-align: left; }
.align-right { text-align: right; }
.right-margin { margin-right: 50px; }
.centre { margin: 0 auto; text-align: center; }
.clear { clear: both; }
.cl-lft { clear: left; }
.cl-rght { clear: right; }
.oauto { overflow: auto; }
.small { font-size: 0.75em; }

img { border: none; }

.quarters { width: 20%; padding: 0; margin: auto 2.5%; display: block; float: left; margin-top: 60px; margin-bottom: 60px; }
.thirds { width: 30%; float: left; margin: 0 1.5%; }

.two-thirds { width: 65%; float: left; margin-right: 2%; box-sizing: border-box; }
.one-third { width: 33%; float: left; margin: 0; box-sizing: border-box; }

/* Specific Wireframes Styles */
.centred { float: none !important; margin-left: auto !important; margin-right: auto !important; float: none !important; overflow: auto; }
.empty-textarea { min-height: 50px; background: red; }
a.block-btn { display: block; text-align: center; background: #444; color: #fff; padding: 8px 15px; margin: 0 0 5px 0; }

.drop-highlight { height: 100px; background: #dfdfdf; }

/* CMS styles */

/* Less than 960 breakpoint */
/* Below 960 we start by scaling */

@media only screen and (max-width: 1560px)  {

	.content {
		width: 100%;
		margin: 0 auto;
		padding: 0;
	}
	.width-12 {
		width: 97.5%;
		margin: 0 1.25%;
	}
	.width-11 {
		width: 89.2%;
		margin: 0 1.25%;
	}
	.width-10 {
		width: 80.8%;
		margin: 0 1.25%;
	}
	.width-9 {
		width: 72.5%;
		margin: 0 1.25%;
	}
	.width-8 {
		width: 64.2%;
		margin: 0 1.25%;
	}
	.width-7 {
		width: 55.8%;
		margin: 0 1.25%;
	}
	.width-6 {
		width: 47.5%;
		margin: 0 1.25%;
	}
	.width-5 {
		width: 39.2%;
		margin: 0 1.25%;
	}
	.width-4 {
		width: 30.8%;
		margin: 0 1.25%;
	}
	.width-3 {
		width: 22.5%;
		margin: 0 1.25%;
	}
	.width-2 {
		width: 14.1%;
		margin: 0 1.25%;
	}
	.width-1 {
		width: 5.8%;
		margin: 0 1.25%;
	}

	.width-8 .width-8, .width-9 .width-9 { width: 100%; }


}
@media only screen and (max-width: 1220px)  {
	.content {
		width: 95%;
		margin: 0 auto;
		padding: 0;
	}
}
@media only screen and (max-width: 800px)  {
	.width-6 {
		width: 75%;
		margin: 0 1.25%;
	}

	.two-thirds { width: 100%; float: none; margin-right: 0; }
	.one-third { width: 100%; float: none; margin-top: 12px; }
}
@media only screen and (max-width: 680px)  {
	.content.admin { width: 95%; }
}
/* Less than 720 breakpoint */
/* Switch to single column for mobile mobile */

@media only screen and (max-width: 1400px)  {
	.rx-1400-hidden{
		display:none!important
	}



	
}

@media only screen and (max-width: 1100px)  {
	.rx-1100-hidden{
		display:none!important
	}

	
}

@media only screen and (max-width: 1000px)  {
	.width-10 {
		width: 75.8%;
		margin: 0 1.25%;
	}
	.width-2 {
		width: 19.1%;
		margin: 0 1.25%;
	}
}

@media only screen and (max-width: 800px)  {

	.content {
		width: 95%;
		margin: 0 auto;
		padding: 0;
	}
	.width-12, .width-11, .width-10, .width-9, .width-8, .width-7, .width-6, .width-5, .width-4, .width-3, .width-2, .width-1, .block, .form-block {
		width: 95%;
		margin: 0 2.5% 0 1.5%;
	}

	.rx-800-hidden{
		display:none!important
	}

	.mobile-navigation{
		display:block!important;
	}

	nav.desktop-navigation{
		display:none!important;
	}

}
